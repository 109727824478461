<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Tipos de incidencias'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col xs="12" sm="12" md="12" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col v-if="rol == 'root'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                ></v-autocomplete>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div v-if="item.cliente == null">
                                        General
                                    </div>
                                    <div v-else>
                                        {{ item.cliente.nombre }}
                                    </div>
                                    <div class="tblSecundario" v-if="item.empresa == null">
                                        Incidencia General
                                    </div>
                                    <div v-else class="tblSecundario">
                                        {{ item.empresa.nombre }}
                                    </div>
                                </td>
                                <td><div>{{item.nombre}}</div></td>
                                <td>
                                    <div class="tblCenter">
                                        <v-chip
                                            class="ma-2 tblPrincipal"
                                            :color="obtenerColor(item.color_id)"
                                            style="width:100px"
                                        >
                                        </v-chip>
                                    </div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <template  v-if="
                                                    item.valor !== 'tipo_incidencia_vacaciones' &&
                                                    item.valor !== 'tipo_incidencia_retardo_justificado' &&
                                                    item.valor !== 'tipo_incidencia_incapacidad'"
                                        >
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Editar</span>
                                            </v-tooltip>
                                            <v-tooltip bottom v-if="permisos.delete">
                                                <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Nombre" 
                                                    class="pa-0 ma-0" 
                                                    v-model="incidencia.nombre" 
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <template>
                                            <v-col xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Color" rules="required">
                                                    <v-select
                                                        outlined
                                                        :items="colores"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Color"
                                                        persistent-hint
                                                        v-model="incidencia.color_id"
                                                        :error-messages="errors"
                                                    >
                                                        <template slot="selection" slot-scope="data">
                                                            <v-chip
                                                                :input-value="data.selected"
                                                                class="chip--select-multi"
                                                                :color="data.item.nombre"
                                                                style="width:100px"
                                                            >
                                                            </v-chip>
                                                        </template>

                                                        <template slot="item" slot-scope="data">
                                                            <template v-if="validateObj(data)">
                                                                <div>{{ data.item }}</div>
                                                            </template>
                                                            <template v-else>
                                                                <v-chip
                                                                    :input-value="data.selected"
                                                                    class="chip--select-multi"
                                                                    :color="data.item.nombre"
                                                                    style="width:100px"
                                                                >
                                                                </v-chip>
                                                            </template>
                                                        </template>
                                                    </v-select>
                                                </ValidationProvider>
                                            </v-col>
                                        </template>
                                        <template v-if="rol == 'root'">
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Cliente"
                                                        :items="clientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                        </template>
                                        <template v-if="rol == 'root' || rol == 'admin'">
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Empresa"
                                                        :items="empresas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="empresa_value"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                    v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                            <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                <v-alert
                                    dense
                                    outlined
                                    type="error"
                                >
                                    El usuario no tiene permiso para editar el registro.
                                </v-alert>
                            </div>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/TipoIncidencias';
import Notify from '@/plugins/notify';
import tipoIncidenciaApi from "../api/tiposIncidencias";
import clienteAPi from "../api/clientes";
import empresaApi from "../api/empresas";
import catalogosApi from "../api/catalogos";


export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: "Cliente",
                    name: "cliente",
                    filterable: false,
                },
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: "Color",
                    name: "color_id",
                    align: 'center',
                    filterable: true,
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {AND:[]},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            incidencia      : {
                id          : 0,
                nombre      : "",
                color_id    : null,
                empresa_id  : null,
                cliente_id  : null,
            },
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isSaving        : false,
            imagen          : '',
            rol             : null,
            datosLogin      : null,
            clientes        : [],
            empresas        : [],
            cliente_value   : null,
            empresa_value   : null,
            colores         : [],
            nombre          : null,
            isLoadingModal  : false,
            permisos :{
                create: false,
                update: false,
                delete: false,
            }
        }
    },
    watch: {
        cliente_value: function(val){
            this.cambiaCliente(val);
        },
    },
    methods: {
        validateObj(data){
            return typeof data.item !== 'object'
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }

            this.$apollo.queries.tipos_incidencia;
        },
        abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoadingModal  = true;
            
            if(accion == "add"){
                this.tituloModal = "Registrar tipo de incidencia.";
                this.imagen     = "/static/modal/tipoIncidenciaCreate.svg";
                
            } else {
                this.tituloModal = "Actualizar tipo de incidencia.";
                this.imagen     = "/static/modal/tipoIncidenciaUpdate.svg";
                this.incidencia.id          = data.id;
                this.incidencia.nombre      = data.nombre;
                this.incidencia.color_id    = parseInt(data.color_id,10);
                this.incidencia.cliente_id  = data.cliente_id;
                this.cliente_value          = data.cliente_id;
                this.incidencia.empresa_id  = data.empresa_id;
                this.empresa_value          = data.empresa_id;
            }

            if (this.rol == "root") {
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value =  this.datosLogin.empresa_id;
            }

            this.isLoadingModal  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        async loadModalData() {
            await this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(this.datosLogin.cliente_id);
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }

        },
        setFilters() {
            this.filters = {AND:[]};
            let nombre = this.nombre;
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } 
            else if (this.rol == "admin-empresa") {
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            } 
            
            this.isLoading = true;

            if(nombre != null && nombre != undefined && nombre != ""){
                this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
            }
            
            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
            }

            if(empresa != null && empresa != undefined && empresa != ""){
                this.filters.AND.push({column:'EMPRESA_ID', value:empresa});
            }

            if (this.rol == "admin") {
                if(this.filters.AND.length == 1){
                    this.filters = {OR: [{column: "CLIENTE_ID", operator:"IS_NULL"},{AND: [{column:'CLIENTE_ID', value:this.datosLogin.cliente_id}]}]};
                }
            } 
            else if (this.rol == "admin-empresa") {
                if(this.filters.AND.length == 2){
                    this.filters = {OR: [{column: "CLIENTE_ID", operator:"IS_NULL"},{AND: [{column:'CLIENTE_ID', value:this.datosLogin.cliente_id}]}]};
                    this.filters = {OR: [{column: "CLIENTE_ID", operator:"IS_NULL"},{AND: [{column:'EMPRESA_ID', value:this.datosLogin.empresa_id}]}]};
                }
                
            } 

            this.paginationData.numberPage = 1;
            this.$apollo.queries.tipos_incidencia.refetch();
            this.resetValues();
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        async resetValues() {
            this.incidencia = {
                id          : 0,
                nombre      : "",
                color_id    : null,
                empresa_id  : null,
                cliente_id  : null,
            };
            
            this.$nextTick(() => {
                this.cliente_value  = null;
                this.empresa_value  = null;
                this.empresas       = [];
                this.nombre = null;
               if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }
            });
        },
        eliminar(data){
            Notify.Alert(
                "¿Estás seguro de eliminar esta incidencia?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    tipoIncidenciaApi.deleteTipoIncidencia(data.id).then(response => {
                        Notify.Success("Operación exitosa", "El tipo de incidencia ha sido eliminado satisfactoriamente.");
                        this.$apollo.queries.tipos_incidencia.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                this.incidencia.cliente_id = this.cliente_value;
                this.incidencia.empresa_id = this.empresa_value;

                this.isLoading = true;
                if(this.accion === "add") {
                    tipoIncidenciaApi.addTipoIncidencia(this.incidencia)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El tipo de incidencia se guardó satisfactoriamente.");
                        this.$apollo.queries.tipos_incidencia.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    tipoIncidenciaApi.updateTipoIncidencia(this.incidencia)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El tipo de incidencia se actualizó satisfactoriamente.");
                        this.$apollo.queries.tipos_incidencia.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        getClientes() {
            let parametros = { activo: true, paginate: false };
            clienteAPi.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        cambiaCliente(id, empresa = null) {
            let parametros = { activo: true, paginate: false, cliente_id: id };
            empresaApi.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
                if (this.accion != "add") {
                    this.incidencia.empresa_id = this.empresa_id;
                }
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        getColores() {
            let parametros = {
                activo: true,
                paginate: false,
                order: "asc",
            };
            catalogosApi.getColores(parametros).then((response) => {
                this.colores = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los colores.");
            });
        },
        obtenerColor(color_id=null){
            var nombreColor = this.colores.find(element => element.id == color_id);
            return nombreColor == null? "":nombreColor.nombre;
        },
        cargaInicial(){
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            this.filters = {AND:[]};
            this.getColores();

            if (this.rol == "root") {
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.filters = {OR: [{column: "CLIENTE_ID", operator:"IS_NULL"},{AND: [{column:'CLIENTE_ID', value:this.datosLogin.cliente_id}]}]};
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value =  this.datosLogin.empresa_id;
                this.filters = {OR: [{column: "CLIENTE_ID", operator:"IS_NULL"},{AND: [{column:'CLIENTE_ID', value:this.datosLogin.cliente_id}]}]};
                this.filters = {OR: [{column: "CLIENTE_ID", operator:"IS_NULL"},{AND: [{column:'EMPRESA_ID', value:this.datosLogin.empresa_id}]}]};
            }
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("catalogo.tipos-incidencias.store")
            this.permisos.update = permisosStorage.includes("catalogo.tipos-incidencias.update")
            this.permisos.delete = permisosStorage.includes("catalogo.tipos-incidencias.delete")
        },
    },
    created() {
        this.cargaInicial();
        this.permisosVista();
    },
     mounted(){
        this.$apollo.queries.tipos_incidencia.skip = false;
    },
    apollo: {
        tipos_incidencia: {
            query       : queries.tipos_incidenciaTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading = true;
                return {
                    whereConditions : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip(){
                return true
            },
            result({loading, error}) {
                this.isLoading    = false;
                this.tableData  = this.tipos_incidencia;
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }

</style>
